<template>
  <div class="grid" v-if="data">
    <div class="bg-nColorHalfWhite px-10 pt-4">
      <div class="flex justify-between items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
            width="125"
            height="55"
            alt="Playda Logo"
          />
        </div>
        <div class="flex gap-4 text-nColorOrange font-InterSemiBold">
          <a :href="marketingURL">Know more about Playda!</a>
          <a :href="contactURL">Contact us</a>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="pt-4">
          <p class="font-InterBold">
            You can choose to change your plan here if you wish
          </p>
        </div>
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing/pricing.webp"
            width="175"
            height="117"
            alt="Playda Pricing Illustration"
          />
        </div>
      </div>
      <div class="w-full border-8 border-nColorLightYellow rounded-3xl">
        <div
          class="w-full border-4 border-nColorYellow rounded-2xl bg-white overflow-visible"
        >
          <div class="w-full flex justify-around" v-if="data.pricing">
            <div class="grid px-4">
              <p class="font-InterBold pt-4">Compare pricing levels</p>
              <div v-if="data.features">
                <div class="pt-6">
                  <div
                    v-for="feature in data.features"
                    :key="feature.slug"
                    class="my-7"
                  >
                    <p class="font-InterMedium text-xs text-nColorLightGray">
                      {{ feature.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(pricing, index) in data.pricing"
              :key="index"
              :class="{
                'pricing-selected': index === selectedPricing,
                'border-l-4 border-4 border-transparent':
                  index != selectedPricing,
              }"
              class="grid text-center cursor-pointer px-10 pt-4"
              @click.prevent="selectPricing(index)"
            >
              <div class="flex justify-center font-InterBold">
                <span v-if="isNumber(pricing.price)">&#8377;</span
                >{{ pricing.price }}
              </div>
              <div>
                <div class="flex justify-center">
                  <div
                    :class="[
                      'font-InterBold',
                      'text-xs',
                      'py-1',
                      'rounded-xl',
                      'border',
                      'border-nColorBGLightGray',
                      getBackgroundColorClass(index),
                      getTextColorClass(index),
                    ]"
                    class="font-InterBold text-xs py-1 rounded-xl border border-nColorBGLightGray w-28"
                  >
                    {{ pricing.name }}
                  </div>
                </div>
                <div
                  v-for="feature in data.features"
                  :key="feature.slug"
                  class="my-6 flex justify-center"
                >
                  <div v-if="pricing[feature.slug] === true">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing/pricing_yes.webp"
                      width="20"
                      height="20"
                      alt="Pricing Yes"
                    />
                  </div>
                  <div v-else-if="pricing[feature.slug] === false">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing/pricing_no.webp"
                      width="20"
                      height="20"
                      alt="Pricing No"
                    />
                  </div>
                  <div v-else>{{ pricing[feature.slug] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-4">
        <button
          @click.prevent="getStarted()"
          :class="[
            'bg-nColorDarkGray rounded-3xl w-44 h-12 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
            { disabled: isSubmitLoading },
          ]"
          :disabled="isSubmitLoading"
        >
          <span v-if="isSubmitLoading" class="flex justify-center">
            <svg
              width="60"
              height="15"
              viewBox="0 0 120 30"
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
            >
              <circle cx="15" cy="15" r="15">
                <animate
                  attributeName="r"
                  from="15"
                  to="15"
                  begin="0s"
                  dur="0.8s"
                  values="15;9;15"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="fill-opacity"
                  from="1"
                  to="1"
                  begin="0s"
                  dur="0.8s"
                  values="1;.5;1"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                <animate
                  attributeName="r"
                  from="9"
                  to="9"
                  begin="0s"
                  dur="0.8s"
                  values="9;15;9"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="fill-opacity"
                  from="0.5"
                  to="0.5"
                  begin="0s"
                  dur="0.8s"
                  values=".5;1;.5"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="105" cy="15" r="15">
                <animate
                  attributeName="r"
                  from="15"
                  to="15"
                  begin="0s"
                  dur="0.8s"
                  values="15;9;15"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="fill-opacity"
                  from="1"
                  to="1"
                  begin="0s"
                  dur="0.8s"
                  values="1;.5;1"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </circle>
            </svg>
          </span>
          <span v-else> Get Started </span>
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { ref } from "vue";
import TokenService from "@/common/token.service";

export default {
  name: "Desktop",
  components: {},
  data() {
    return {
      data: null,
      selectedPricing: 0,
      isSubmitLoading: false,
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
    };
  },
  created() {
    ApiService.get(apiResource.getPricingDetails, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  methods: {
    getBackgroundColorClass(index) {
      const classes = [
        "bg-diy-blue-bg",
        "bg-diy-green-bg",
        "bg-diy-pink-bg",
        "bg-diy-purple-bg",
      ];
      return classes[index % classes.length];
    },
    getTextColorClass(index) {
      const classes = [
        "text-diy-blue-text",
        "text-diy-green-text",
        "text-diy-pink-text",
        "text-diy-purple-text",
      ];
      return classes[index % classes.length];
    },
    selectPricing(index) {
      this.selectedPricing = index;
    },
    getStarted() {
      this.isSubmitLoading = true;
      const pricingArray = ref(this.data.pricing);
      var pricingDetails = pricingArray.value[this.selectedPricing];
      if (pricingDetails.action === "form") {
        window.location.replace(this.contactURL);
      } else {
        TokenService.saveToken("pricing_plan", pricingDetails.slug);
        this.$router.push({
          name: "Register",
        });
      }
    },
    isNumber(value) {
      return typeof value === "number";
    },
  },
  computed: {},
};
</script>
  
<style scoped lang="postcss">
.pricing-selected {
  @apply bg-nColorLightYellow border-l-4 border-r-4 border-nColorYellow;
}
</style>
  