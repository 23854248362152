<template>
  <div class="grid">
    <Desktop class="hidden md:block" />
    <Mobile class="block md:hidden" />
  </div>
</template>
  
  <script>
import Desktop from "@/components/Pricing/Desktop.vue";
import Mobile from "@/components/Pricing/Mobile.vue";

export default {
  name: "Pricing",
  components: { Desktop, Mobile },
  data() {
    return {};
  },
  created() {},

  computed: {},
};
</script>
  
  <style scoped></style>
  